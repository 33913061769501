import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.indexOf(environment.apiConstants.GATEWAY_BASE_URL) !== -1) {
      const sessionToken = localStorage.getItem('token');
      if (sessionToken) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${sessionToken}`
          }
        });
      }
    }

    return next.handle(request);
  }
}
