import { Component, OnInit } from '@angular/core';
import { AlertModel } from '../../../../core/models/alert.model';
import { UserService } from '../../../../core/services/user.service';
import { TokenHelper } from '../../../../utils/helpers/token.helper';
import { AlertTypes } from '../../../../utils/enums/alert-types.enum';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantLogoHelper } from '../../../../utils/helpers/tenant-logo.helper';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  alertModel: AlertModel;
  loading = false;
  tenantLogoUrl: string;

  constructor(private userService: UserService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.tenantLogoUrl = TenantLogoHelper.getLogoUrl();
  }

  authenticate() {
    this.loading = true;
    this.userService.login().subscribe(
      token => this.validateToken(token),
      error => this.errorEncountered(error?.errorMessage));
  }

  private validateToken(token: string) {
    const tokenData = TokenHelper.parseJwtToken(token);
    if (tokenData.prm.includes('tenant_admin') && tokenData.prm.includes('jhh_datalogist')) {
      this.loading = false;
      localStorage.setItem('token', token);
      this.saveCookie(token);
      this.router.navigate(['home'], {relativeTo: this.route.parent});
    } else if (tokenData.prm.includes('tenant_admin')) {
      localStorage.setItem('token', token);
      this.redirectToOutSidePage(environment.apiConstants.ADMIN_PORTAL_URL + '?redirected=true', token);
    }  else if (tokenData.prm.includes('jhh_datalogist')) {
      localStorage.setItem('token', token);
      this.redirectToOutSidePage(environment.apiConstants.CLINICIAN_PORTAL_URL + '?redirected=true', token);
    } else {
      this.userNotAllowed();
    }
  }

  private redirectToOutSidePage(url: string, token: string) {
    this.loading = false;
    this.saveCookie(token);
    window.location.href = url;
  }

  private  saveCookie(token: string) {
    document.cookie = 'token=' + token + '; domain=.' +
      location.hostname.split('.').reverse()[1] + '.' +
      location.hostname.split('.').reverse()[0] + '; path=/';
  }

  private userNotAllowed() {
    this.userService.logout();
    this.loading = false;
    this.alertModel = {
      type: AlertTypes.error,
      message: 'You don\'t have permissions'
    };
  }

  private errorEncountered(error: string) {
    this.loading = false;
    this.alertModel = {
      type: AlertTypes.error,
      message: error || 'Something went wrong trying to authenticate.'
    };
  }

  signUp() {
    this.loading = true;
    this.userService.signUp().subscribe(
      token => this.validateToken(token),
      error => this.errorEncountered(error?.errorMessage));
  }
}
