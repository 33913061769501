import { Component, Input } from '@angular/core';
import { AlertModel } from '../../../core/models/alert.model';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  @Input() alertModel: AlertModel;

  constructor() { }

}
