import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  token: string;

  constructor() { }

  goToAdminPortal() {
    window.location.href = environment.apiConstants.ADMIN_PORTAL_URL + '?redirected=true';
  }

  goToClinicianPortal() {
    window.location.href = environment.apiConstants.CLINICIAN_PORTAL_URL + '?redirected=true';
  }

}
