<button
  mat-stroked-button
  [ngClass]="{
    'full-width': fullWidth,
    'medium-width': mediumWidth
  }"
  class="secondary-button"
  [color]="color"
  [disabled]="disabled"
  [type]="type"
  (click)="buttonClick()">
  <ng-content></ng-content>
</button>
