<app-header></app-header>
<div class="row center-xs description">
  Where do you want to go?
</div>
<div class="row center-xs">
  <app-card
    class="col-xs-3"
    (click)="goToAdminPortal()">
    <div class="portal-card admin">
      ADMIN <br> PORTAL
    </div>
  </app-card>
  <app-card
    class="col-xs-3"
    (click)="goToClinicianPortal()">
    <div class="portal-card clinician">
      CLINICIAN <br> PORTAL
    </div>
  </app-card>
</div>
