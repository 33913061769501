<div class="row center-xs login-container">
  <div class="col-xs-4">
    <div class="box-container">
      <div class="logo-container row center-xs">
        <img src="assets/images/all-health-logo.svg"
             alt="JHC Logo"
             class="col-xs-6 all-health logo">
        <img [src]="tenantLogoUrl"
             alt="Tenant Logo"
             class="col-xs-6 logo"
             *ngIf="tenantLogoUrl">
      </div>
      <app-alert
        *ngIf="alertModel"
        [alertModel]="alertModel">
      </app-alert>
      <mat-spinner
        [diameter]="40"
        *ngIf="loading"
        class="loader">
      </mat-spinner>
      <p class="description">Log In to your account with Azure</p>
      <app-button
        [color]="'primary'"
        [mediumWidth]="true"
        (click)="authenticate()">
        LogIn
      </app-button>
      <p class="description">
        You don't have an account?
      </p>
      <app-secondary-button
        [color]="'primary'"
        [mediumWidth]="true"
        (click)="signUp()">
        Sign Up
      </app-secondary-button>
    </div>
  </div>
</div>
