import { environment } from '../../../environments/environment';
import { TenantsEnum } from '../enums/tenants.enum';
import { TenantLogoUrlsEnum } from '../enums/tenant-logo-urls.enum';

export class TenantLogoHelper {

  static getLogoUrl(): string {
    switch (environment.apiConstants.CUSTOMER_TENANT) {
      case TenantsEnum.seha:
        return TenantLogoUrlsEnum.seha;
        break;
      case TenantsEnum.ccad:
        return TenantLogoUrlsEnum.ccad;
        break;
      case TenantsEnum.glucare:
        return TenantLogoUrlsEnum.glucare;
        break;
      case TenantsEnum.uhg:
        return TenantLogoUrlsEnum.uhg;
        break;
      default:
        return null;
    }
  }
}
