import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MsalService} from '@azure/msal-angular';
import {AuthenticationParameters} from 'msal';
import {map, switchMap} from 'rxjs/operators';
import {from, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private msalService: MsalService) { }

  private getJHToken(azureToken: string): Observable<string> {
    return this.http.post<any>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.USER_V2_API_URL}/users/@me/tokens`,
      {},
      {
        headers: {
          Authorization: `Bearer ${azureToken}`,
        }
      }
    ).pipe(
      map(response => response.token)
    );
  }

  private getAzureToken(authority: string): Promise<string> {
    const loginRequest: AuthenticationParameters = {
      scopes: [environment.apiConstants.AZURE_B2C_SCOPE, 'openid'],
      authority
    };
    return this.msalService.loginPopup(loginRequest).then(result => {
      return result.idToken.rawIdToken.toString();
    });
  }

  login(): Observable<string> {
    return from(this.getAzureToken(environment.apiConstants.AZURE_B2C_AUTHORITY)).pipe(
      switchMap(azureToken => {
        return this.getJHToken(azureToken);
      })
    );
  }

  signUp(): Observable<string> {
    return from(this.getAzureToken(environment.apiConstants.AZURE_B2C_AUTHORITY_SIGN_UP)).pipe(
      switchMap(azureToken => {
        return this.getJHToken(azureToken);
      })
    );
  }

  logout() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    this.msalService.logout();
  }
}
