import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { AlertComponent } from './alert/alert.component';
import { MatButtonModule } from '@angular/material/button';
import { SecondaryButtonComponent } from './secondary-button/secondary-button.component';
import { HeaderComponent } from './header/header.component';
import { MatIconModule } from '@angular/material/icon';
import { CardComponent } from './card/card.component';

const components = [
  ButtonComponent,
  AlertComponent,
  SecondaryButtonComponent,
  HeaderComponent,
  CardComponent
];

@NgModule({
  declarations: [...components, CardComponent],
  exports: [...components],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class SharedModule { }
