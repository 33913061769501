<div class="header">
  <div class="col-xs-11 logo-container">
    <img src="assets/images/all-health-logo.svg"
         alt="JHC Logo"
         class="logo">
    <img [src]="tenantLogoUrl"
         alt="Tenant Logo"
         class="logo"
         *ngIf="tenantLogoUrl">
  </div>
  <div class="col-xs-1 button-container">
    <app-secondary-button
      [fullWidth]="true"
      (click)="logout()">
      Log Out
    </app-secondary-button>
  </div>
</div>
