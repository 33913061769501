import { NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { MsalModule } from '@azure/msal-angular';
import { UserService } from './services/user.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptor/auth.interceptor';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    MsalModule.forRoot({
      auth: {
        clientId: environment.apiConstants.AZURE_B2C_CLIENT_ID,
        validateAuthority: false,
        postLogoutRedirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    })
  ],
  providers: [
    UserService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ]
})
export class CoreModule { }
