import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.scss']
})

export class SecondaryButtonComponent {

  @Input() disabled: boolean;
  @Input() fullWidth = false;
  @Input() mediumWidth = false;
  @Input() type = 'button';
  @Input() color: 'primary' | 'basic' | 'accent' | 'warn' = 'primary';
  @Output() clickEvent: EventEmitter<void> = new EventEmitter<void>();

  buttonClick() {
    this.clickEvent.emit();
  }

}
